.split-header {
  font-weight: bold;
  font-size: 20px;
  color: #5a6169;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
.leftText {
  display: block;
  text-align: left;
  font-size: medium;
  float: left;

  max-width: 72%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightText {
  text-align: right;
  float: right;
  font-size: medium;
}
.split-monitor-box-three {
  font-size: 15px;
  display: flex;
  font-weight: bold;
  color: #ffffff;
  padding: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.split-monitor-box-three .label {
  font-size: 20px;
}
.split-monitor-box-three .value {
  font-size: 35px;
}
.split-monitor-box-three-warning {
  font-size: 15px;
  font-weight: bold;
  background-color: #ffff00;
  color: #000000;
  padding: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  display: flex;
}
.split-monitor-box-three-warning .label {
  font-size: 20px;
}
.split-monitor-box-three-warning .value {
  font-size: 35px;
}

.split-monitor-row {
  display: flex;
  height: 20vh;
  padding: 0px;
  margin: 0px;
}

.split-monitor-box {
  display: flex;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  padding: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.split-monitor-box .label {
  font-size: 30px;
}
.split-monitor-box .value {
  font-size: 60px;
}
.split-monitor-box-warning {
  font-weight: bold;
  background-color: #ffff00;
  color: #000000;
  padding: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  display: flex;
}
.split-monitor-box-warning .label {
  font-size: 30px;
}
.split-monitor-box-warning .value {
  font-size: 70px;
}
.split-monitor-box-alert {
  font-weight: bold;
  background-color: #ff0000;
  color: #000000;
  padding: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  display: flex;
}
.split-monitor-box-alert .label {
  font-size: 30px;
}
.split-monitor-box-alert .value {
  font-size: 70px;
}
.agents-header {
  background-color: #cccccc;
  font-weight: bold;
  font-size: 20px;
}
.agents-table-header {
  background-color: #808080;
  font-weight: bold;
  color: #ffffff;
}
.agents-table-data-cell {
  background-color: #ffffff;
}
.agents-table-data-cell-warning {
  background-color: #ffff00;
  font-weight: bold;
}
.agents-table-data-cell-green {
  background-color: #00ff00;
  font-weight: bold;
}
.agents-table-data-cell-orange {
  background-color: #ff8300;
  font-weight: bold;
}
/* .agents-table-data-cell-alert { background-color: #FF0000; font-weight: bold; color: #ffffff; } */
/* .agents-table-data-cell-loggedout { background-color: #FF0000; font-weight: bold; color: #FFFFFF; } */
.MuiCircularProgress-colorPrimary {
  color: red;
}

.agents-table-data-cell-alert {
  background-color: #ff0000;
  font-weight: bold;
  color: white;
  /* animation: redtotransparentagent 2s ease-in-out infinite; */
}

.blink-red {
  font-size: 30px;
  animation: redtotransparent 2s ease-in-out infinite;
  font-weight: bold;
  color: #ffffff;
  padding: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  display: flex;
}
.blink-red .label {
  font-size: 30px;
}
.blink-red .value {
  font-size: 60px;
}

.blink-red-three {
  font-size: 15px;
  animation: redtotransparent 2s ease-in-out infinite;
  font-weight: bold;
  color: #ffffff;
  padding: 1px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  display: flex;

  /* background-color: #7030A0; */
  /* background-color: aqua; */
}
.blink-red-three .label {
  font-size: 20px;
}
.blink-red-three .value {
  font-size: 35px;
}
@keyframes redtotransparent {
  from {
    background-color: red;
    /* color: white; */
  }
  to {
    /* background-color:#7030A0; */
  }
}
@keyframes redtotransparentagent {
  from {
    background-color: red;
    color: white;
  }
  to {
    background-color: white;
    color: black;
  }
}

@media (min-width: 2055px) {
  .leftText {
    font-size: x-large;
  }
  .rightText {
    font-size: x-large;
  }
}
@media (min-width: 1600px) and (max-width: 2050px) {
  .leftText {
    /* max-width:  55%; */
    font-size: large;
  }
  .rightText {
    font-size: large;
  }
  div.MuiCardHeader-content {
    width: 100%;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .leftText {
    max-width: 69%;
    font-size: medium;
  }
  .rightText {
    font-size: medium;
  }
  div.MuiCardHeader-content {
    width: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .leftText {
    max-width: 67%;
    font-size: medium;
  }
  .rightText {
    font-size: medium;
  }
  div.MuiCardHeader-content {
    width: 100%;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .leftText {
    max-width: 70%;
    font-size: small;
  }
  .rightText {
    font-size: small;
  }
  div.MuiCardHeader-content {
    width: 100%;
  }
}

@media (min-width: 960px) and (max-width: 1100px) {
  .leftText {
    max-width: 65%;
    font-size: small;
  }
  .rightText {
    font-size: small;
  }
  div.MuiCardHeader-content {
    width: 100%;
  }
}

@media (min-width: 801px) and (max-width: 959px) {
  .leftText {
    /* max-width: 60%; */
    font-size: medium;
  }
  .rightText {
    font-size: medium;
  }
  div.MuiCardHeader-content {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .leftText {
    /* max-width: 70%; */
    font-size: large;
  }
  .rightText {
    font-size: large;
  }
  div.MuiCardHeader-content {
    width: 100%;
  }
}

/*
@media (min-width:1440px) and (max-width:1580px){
  .leftText{
    max-width: 70%;
  }
}

@media (min-width:1366px) and (max-width:1440px){
  .leftText{
    max-width: 25vw;
  }
}

@media (min-width:1280px) and (max-width:1366px){
  .leftText{
    max-width: 25vw;
  }
}

@media (min-width:1920px){
  .leftText{
    max-width: 25vw;;
  }
}

@media (max-width:959px){
  .leftText{
    max-width: 55vw;
  }
} */
