body
{
	margin: 0;
	padding: 0;
	background-repeat: repeat-x;
	background-color: #ffffff;
	font: 13px ;
	font-family: "Poppins";

}

form
{
	margin: 0px;	
}

a
{
	color: #000000;
	font-size: 12px;
}

th
{
	background-color: #DDDDDD;
}

label
{
	font-weight: bold;
	font-family: "Poppins";

}

pre
{
	font: 12px ;
	font-family: "Poppins";

	white-space: pre-wrap;
}

#pagebg, #topheader, #secondaryheader, #breadcrumbs, .zoom-icon, #siteinfo
{
	display: none;
}

#container
{
	margin-left: 0px;
	margin-right: 0px;
	padding: 0;
	color: #454545;
	text-align: left;
	min-height: 800px;
	width: max-content;
}

#toptitle
{
	padding: 15px;
	width:600px;
	/* height: 76px; */
	vertical-align: bottom;
	float:left;
	font-family: "Poppins";

}

#toptitle a
{
	text-transform: uppercase;
	font-size: 24px;
	font-family: "Poppins";
	text-decoration: none;
	vertical-align: bottom;
	color: #FFFFFF;
	letter-spacing: 1px;
}

#toptitle a span.blue
{
	color: #AFDEFA;
}

#toptitle a span.bigger-first-letter
{
	font-size: 30px;
	font-family: "Poppins";

}

#toplinkarea
{
	padding: 10px 0 0 5px;
	height: 40px;
	float: left;
}

.toplinkitems
{
	padding-left: 10px;
	list-style: none outside none;
	margin: 0;
}

.toplinkitems li
{
	float: left;
	position: relative;
	width: 140px;
}

.toplinkitems a
{
	padding: 0.6em 0.5em;
	text-decoration: none;
	display: block;
	font-weight: bold;
	font-family: "Poppins";
	color: #ffffff;
	text-transform: uppercase;
}

.toplinkitems a:hover
{
	background-image: url(../images/topmenu_bg.gif);
	background-repeat: no-repeat;
	background-position: center;
}

.toplinkitems a.nobg:hover
{
	background-image: none;
}

#toplogo
{
	padding: 4px 0 0 5px;
	float: right;
	height: 66px;
}

#contentwrapper
{
	padding: 10px 0px 30px 10px; 
	margin: 0;
	background-color: #E8F7FC;
	border: 1px solid #000000;
	white-space: pre-wrap;
}

#contentwrapper h1
{
	font-size: 13pt;
	font-weight: bold;
	font-family: "Poppins";

}

h3
{
	margin: 15px 0;
	padding: 10px 0;
}

#contentarea
{
	background-color: #ffffff;
	border: 1px solid #000000;
	padding: 5px;
	height: auto !important;
}

#ticketHomeTable
{
	background-color: #ffffff;
}

#ticketHomeTable td.border
{
	border: 1px solid #000000;
}

.homeTicketCharts
{
	width: 370px;
	height: 350px;
}

.homeChartTitle
{
	background-image: url(../images/homeChartTitleBg.gif);
	background-repeat: repeat-x;
	border-top: 1px solid #000000;
	border-bottom: 1px solid #000000;
	height: 22px;
	text-align: center;
	padding: 4px 10px 0 0;
	font-weight: bold;
	font-family: "Poppins";
	color: #000000;
}

.red
{
	background-color: #ea1d36;
	font-weight: bold;
	font-family: "Poppins";

}

.green
{
	background-color: #33FF00;
	font-weight: bold;
	font-family: "Poppins";

}

.yellow
{
	background-color: #FFFF00;
	font-weight: bold;
	font-family: "Poppins";

}

.redfont
{
	color: #000000;
	font-weight: bold;
	background-color: #ea1d36;
}

.greenfont
{
	color: #000000;
	font-weight: bold;
	font-family: "Poppins";
	background-color: #33FF00;
}

.yellowfont
{
	color: #000000;
	font-weight: bold;
	font-family: "Poppins";
	background-color: #FFFF00;
}

/* dsr page */

.qtitle
{
	font-weight: bold;
	font-family: "Poppins";
	width: 250px;
}

.chartarea
{
	border: 1px solid #000000;
}

.version
{
	color: #ffffff;
	float: right;
	padding: 10px;
}

.tip-table-header
{
	background-color: #4F81BD;
	font-weight: bold;
	color: #ffffff;
	white-space: nowrap;
	font-size: 11px;
	font-family: "Poppins";

} 

.tip-table-row
{
	background-color: #D3DFEE;
	color: #000000;
	font-size: 11px;
	font-family: "Poppins";

}

.tip-table-alt-row
{
	background-color: #ffffff;
	color: #000000;
	font-size: 11px;
	font-family: "Poppins";

}

.chartDiv
{
	padding: 5px;
}

.area-row-header
{
	background-color: #DDDDDD;
	font-weight: bold;
	font-family: "Poppins";
	color: #000000;
	padding: 10px;
}

.area-row-footer
{
	font-weight: bold;
	color: #000000;
	font-style: italic;
	font-family: "Poppins";

}

.accordion
{
	width: 240px;
}

.inline
{
	padding-right: 20px;
	display: inline;
}

tr.table-row-header
{
	background-color: #004877;
	color: #FFFFFF;
}
		
tr.table-row-header td
{
	padding: 10px;
}
		
tr.table-row-empty
{
	background: none;
	font-size: 1px;
	font-family: "Poppins";

}

tr.table-row-sub-header
{
	background-color: #4561A2;
	color: #FFFFFF;
	height: 30px;
}
		
tr.table-row-sub-header td
{
	padding: 10px;
}

tr.table-row-column-headers
{
	background-color: #DDDCD7;
}
		
tr.table-row-data
{
	background-color: #FFFFFF;
	height: 30px;
	vertical-align: middle;
}

.vip-highlight td
{
	background-color: #FEEF2A;
	font-weight: bold;
	font-family: "Poppins";

}

span.contact-top-right
{
	float: right;
	padding: 10px 0px 0px 0px;	
}

.note {
	font: 12px ;
	font-family: "Poppins";
	color: #ea1d36;
}

.outage-area-link
{
	display: inline;
	font-size: 11px;
	font-family: "Poppins";

}

.outage-area-link a, .outage-area-link a:visted
{
	text-decoration: none;
}

.outage-area-link a:hover
{
	text-decoration: underline;
}

.float-left
{
	float: left;
}

.float-right
{
	float: right;
}

.sortable
{
	list-style-type: none;
	margin: 0;
	padding: 5px 0px 0px 0px;
}

.sortable
{
	list-style-type: none;
	margin: 0;
	padding: 5px 0px 0px 0px;
}

.sortable li
{
	margin: 0 0px 5px 0px;
	padding: 3px;
	font-size: 12px;
	font-weight: bold;
	font-family: "Poppins";

}

.ui-state-highlight
{
	height: 1.5em;
	line-height: 1.2em;
}

.no-print
{
	display: none;
}